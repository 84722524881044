class window.Application
  constructor: () ->
    @_$header = $('.page__header')
    @_$page = $('.page')
    @_$fileInput = $('[type="file"]')

    @file_api = if window.File && window.FileReader && window.FileList && window.Blob then true else false

    @_init()

  _init: ->
    $(document).foundation()

    @_resize()

    new window.Form()

    if $('#section-catalog').length
      new window.Catalog()

    new Swiper '.hero__slider .swiper-container',
      fade: { crossFade: true }
      effect: 'fade'
      slidesPerView: 'auto'
      loop: true
      speed: 1
      autoplay: 5000
      pagination: '.hero__slider .swiper-pagination'
      paginationClickable: true

    new Swiper '.tooling__slider .swiper-container',
      slidesPerView: 'auto'
      loop: true
      autoplay: 5000
      pagination: '.tooling__slider .swiper-pagination'
      paginationClickable: true

    new Swiper '.print__slider .swiper-container',
      fade: { crossFade: true }
      effect: 'fade'
      slidesPerView: 'auto'
      pagination: '.print__slider .swiper-pagination'
      paginationClickable: true

    $("a.scroll-to").mPageScroll2id
      offset: 55
      scrollEasing: 'swing'

    $('.sidebar .list--num .list__item').viewportChecker
      classToAddForFullView: 'visible animated fadeIn'
      offset: 100

    @_events()

  _events: ->
    $(window).on 'resize', () =>
      clearTimeout @_resizeTimeout
      @_resizeTimeout = setTimeout () =>
        @_resize()
      , 150

    @_$fileInput.on 'change', (event) =>
      $self = $(event.currentTarget)
      $value = $self.val()

      $textBlock = $self.parent('.input-file').find('span')

      if $value != ''

        if @file_api && $self[0].files[0]
          $textBlock.text($self[0].files[0].name).attr 'title', $self[0].files[0].name
        else
          $textBlock.text($value.replace("C:\\fakepath\\", '')).attr $value.replace("C:\\fakepath\\", '')
      else
        $textBlock.text('Загрузите свой эскиз').attr 'title', 'Загрузите свой эскиз'

  _resize: ->
    viewportWidth = window.screen.width
    headerHeight = @_$header.outerHeight()

    if viewportWidth < 1200
      initScale = viewportWidth / 1200
      $('meta[name="viewport"]').attr "content", "width=device-width, initial-scale=" + initScale + ""
      @_$header.css 'position', 'relative'
      @_$page.css 'padding-top', 0
    else
      $('meta[name="viewport"]').attr "content", "width=device-width, initial-scale=1.0"
      @_$header.css 'position', 'fixed'
      @_$page.css 'padding-top', headerHeight

    $('.page__header').css
      'max-width': $("body").prop("scrollWidth")

  hideModal: (modal) ->
    setTimeout ->
      modal.foundation 'close'
    , 6000

  resetForm: (form) ->
    if form.length
      form[0].reset()
      form.find('label.error').remove(0)
      form.find('input.error').removeClass('error')
      form.find('[role].wpcf7-not-valid-tip').remove()
      form.find('[role].wpcf7-response-output').hide(0)
      form.find('.wpcf7-not-valid').removeClass 'wpcf7-not-valid'

$ ->
  window.app = new window.Application()
